@media (max-width: 768px) {
    .col-12 {
        padding-left: 0;
        padding-right: 0;
    }

    .mb-4 {
        margin-bottom: 1rem;
    }
}

.bioContainer{
    text-align: left !important;
    justify-content: left !important;
    align-items: left !important;
}

.coverImg {
    width: 86%;
}

.upload_btn {
    bottom: 4px;
    transform: translate(-70%, 50%);
    color: #138808;
    -webkit-text-stroke: 1px #FF9933;
    font-size: 20px;
}

.profileInfo {
    right: 5%;
}

.profile_image {
    width: 180px;
    height: 180px;
    object-fit: cover;
}

.upload_btn_center {
    /* left: 50%; */
    transform: translateY(-90%);
    background: none;
    border: none;
    font-size: 20px;
    color: #138808;
    -webkit-text-stroke: 1px #FF9933;
}

.profileName {
    color: #138808;
    -webkit-text-stroke: 1px #FF9933;
    font-weight: 600;
}

.bio_popup,
.name_popup {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    width: 50%;
    background-color: #e7f3e6;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 999;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.name_input,
.bio_input {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    min-height: 200px;
}

.buttonContainer {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.save_button {
    margin-top: 5px;
    display: block;
    text-align: center;
    padding: 5px 10px;
    background-color: #FF9933;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
}

.bio_label {
    font-weight: bold;
    margin-bottom: 5px;
    top: 12px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #138808;
    -webkit-text-stroke: 1px #FF9933;
}

.bio_text {
    margin: 0;
    /* word-break: break-all; */

}

.read_more_button {
    background: none;
    border: none;
    color: #138808;
    cursor: pointer;
    text-decoration: underline;
    margin-left: 5px;
}

.full_bio_popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #e7f3e6;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    max-height: 400px;
    overflow: scroll;
}

.close_button {
    display: block;
    padding: 5px 0;
    background-color: #FF9933;
    color: #fff;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    margin-left: 45%;
    padding: 5px 10px;
}

.close_button:hover,
.save_button:hover,
.share_button:hover {
    background-color: #138808;
}

.upload_btn_mob {
    color: #138808;
    /* left: 45%; */
    transform: translate(-72%, -30%);
    -webkit-text-stroke: 1px #FF9933;
    font-size: 20px;
    position: absolute;
}

.upload_btn_center_mob {
    transform: translate(90%,-30%);
    font-size: 20px;
    color: #138808;
    -webkit-text-stroke: 1px #FF9933;
}

.share_button {
    background-color: #FF9933;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 5px;
}

.profileNameMob {
    color: #138808;
    -webkit-text-stroke: 1px #FF9933;
    font-weight: 600;
    font-size: 16px;
}

.bio_label_Mob {
    font-weight: bold;
    margin-bottom: 4px;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #138808;
    -webkit-text-stroke: 1px #FF9933;
}

.bio_text_mob {
    margin: 0;
    font-size: 12px;
    line-height: 14px;
    /* word-break: break-all; */
}

.full_bio_popup_mob {
    position: fixed;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #e7f3e6;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    max-height: 300px;
    overflow: scroll;
    width: 88%;
}

.share_button_mob {
    background-color: #FF9933;
    color: #fff;
    border: none;
    padding: 2px 5px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 5px;
}

.sharedialog_mob {
    position: fixed;
    top: 25%;
    left: 45%;
    transform: translate(-50%, -50%);
    background: linear-gradient(to bottom, #FF9933 5%, #138808 50%);
    border: 1px solid #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    z-index: 9999;
}

/* ********************************************Tabs********************************** */


@media (max-width: 360.98px) {
    .navItem {
        margin: 0 2px;
    }

    .title {
        display: none;
    }
}


@media (min-width: 360px) {
    .navItem {
        width: calc(100% / 7);
        margin: 0;
        text-align: center;
    }
}

/* ******************************************Video & Clips card******************************************** */

.blur {
    filter: blur(2px);
    transition: filter 0.3s ease-in-out;
}

.show {
    filter: blur(0);
}

.card_transition {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 0.9s ease-out, transform 0.5s ease-out;
}

.card_transition.show {
    opacity: 1;
    transform: translateY(0);
}

.items_list {
    margin-right: 0;
    margin-left: 0;
}

.p-2 {
    padding: 0.5rem !important;
}

.listing_card_video {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: none;
}

.clip_card {
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}

.clip_card:hover .clip_description {
    opacity: 1;
    visibility: visible;
}

.clip_description {
    position: absolute;
    /* top: 80%; */
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    /*margin: 5px;*/
    text-align: bottom;
    height: 100px;
    vertical-align: middle;
    /* transition effect. not necessary */
    transition: opacity 1s, visibility 1s;
    background: rgba(51, 51, 51, 0.95);
}

.listing_thumb_image_video img,
.card_img_top {
    height: auto;
    width: 100%;
    border-radius: 8px;
}

.clip_description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.subCatName {
    margin-top: 0px;
    margin-bottom: 0px;
    white-space: nowrap;
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
    animation: typing 4s steps(22, end) forwards;
    overflow: hidden;
    color: #138808;
    -webkit-text-stroke: 1px #FF9933;
}

@keyframes typing {
    0% {
        width: 0%
    }

    100% {
        width: 100%
    }
}

.read_more_button_card {
    margin-top: 20px;
    background: none;
    border: none;
    color: #138808;
    cursor: pointer;
    text-decoration: underline;
}

.listing_title_video {
    /*overflow: hidden;*/
    font-size: 1.10rem;
    margin-bottom: 10px;
    color: #FF9933;
    font-weight: 700;
}

.clip_title_video {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #FF9933;
    font-weight: 600;
}

.mob_listing_title_video {
    /*overflow: hidden;*/
    font-size: 1.05rem;
    color: #FF9933;
    padding-bottom: 10px;
    font-weight: 600;
}

.listing_info_box_desc_newsArt_video {
    /*overflow: hidden;*/
    font-weight: 500;
    font-size: 0.8rem;
    padding-bottom: 10px;
}

.basicVideoSubCat {
    overflow: hidden;
    font-weight: 700;
    font-size: 12px;
    font-style: italic;
    color: #333333;
}

/* *********************************************Basic Cards************************************************** */

.basic_listing_info {
    width: 100%;
}

.basic_listing_info_box_desc {
    overflow: hidden;
    height: 25px;
    margin-bottom: 5px;
    font-weight: 500;
}

.listing_info_box_basic_home {
    height: auto;
    margin-bottom: 10px;
    background: #ffffff;
}

.home_thumb_image_basic {
    border-right: 1px solid;
    background: #ffffff;
    border-color: #cccccc;
    height: auto;
}

.home_thumb_image_basic_img {
    object-fit: cover;
    height: auto;
}

.home_link {
    width: fit-content;
    font-size: 1.10rem;
    color: #FF9933;
}

.basic_home_listing_info_box {
    overflow: hidden;
    margin-bottom: 5px;
    font-size: 14px;
}

.basic_home_listing_info_box_desc {
    overflow: hidden;
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 14px;
    font-style: italic;
}

.name_span {
    font-style: normal;
}

.viewed_mark_home {
    position: absolute;
    right: 5px;
    bottom: 8px;
    color: #138808;
    background: white;
    border-radius: 50%;
    border: solid 1px #fff;
}

.viewed_right_home {
    right: 20px;
}

.card_float {
    float: left;
    justify-content: left;
    align-items: left;
    align-self: start;
}

/* ********************************************************************************** */
.contentCard {
    border-radius: 20px;
    transition: transform 0.3s ease; /* Smooth transition effect */
}

.contentCard:hover {
    transform: scale(1.12); /* Increase size to 105% on hover */
    z-index: 9;
    visibility: visible;
    opacity: 1;
    color: #138808;
    cursor: pointer;
}

.contentText {
    font-size: 1rem;
    color: #FF9933;
}

.contentText:hover {
    color: #138808;
}

.card_img_top {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}