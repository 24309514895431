.website_input {
    padding-left: 50px;
}

.website_prefix_label:before {
    content: 'www.';
    position: absolute;
    top: -1px;
    bottom: 0;
    z-index: 3;
    display: block;
    padding: 0.525rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
}

.ad_container {
    min-height: 10rem;
}

.span_margin {
    margin-right: 1rem;
}

.banner_ad {
    background: #000080;
    /* padding-top: 40px; */
    width: 100%;
    height: auto;
}

.banner_ad_login {
    display: none;
}

.ad_position {
    position: relative;
}

.ad_position_clip {
    position: relative;
    transform: translate(0%, 50%);
}

.ad_position_clip_mob {
    position: relative;
    transform: translate(0%, 50%);
}

.clipAd_container {
    position: relative;
    display: inline-block;
}

.video_container_ad_mob {
    position: relative;
    /* margin-bottom: 20px; */
    transition: 'transform 0.6s ease';
    display: block;
}

.BillBoard_Ad {
    width: 970px;
    height: 250px;
    margin: auto auto auto auto;
    background: #000080;
}

.resp{
    padding-top: 40px;
}

@media(max-width: 999px) {
    .BillBoard_Ad {
        display: none;
    }
}

@media screen and (min-device-width: 1000px) {
    .large_rect {
        display: none;
    }
    .billBoard {
        display: block;
    }
}

.LeaderBoard_Add {
    text-align: center;
    margin: auto;
    width: 728px;
    height: 90px;
    background: #000080;
}

.redirect_link {
    color: #FF9933;
}

@media screen and (max-width: 567px) {
    .advt_section {
        margin-top: 35px;
    }
}