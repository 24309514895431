/*******************BasicfCards*************/
.basic_listing_info:nth-child(even) {
    /* padding-left: 0px; */
}

.basic_listing_info:nth-child(odd) {
    /* padding-right: 1px; */
    /* padding-left: 0; */
}

.basic_listing_info {
    padding-left: 8px;
    padding-right: 8px;
}



.listing_info_box_basic {
    /* border: 1px solid; */
    height: auto;
    margin-bottom: 10px;
    margin-left: .25rem;
    margin-right: .25rem;
    background: #ffffff;
    /* border-color: #cccccc; */
}

.homeVIdeo {
    margin-left: 80px;
}

.newsArt_thumb_video_basic {
    border-right: 1px solid;
    background: #ffffff;
    border-color: #cccccc;
    height: 105px;
    width: 200pxpx;
    max-width: 200px;
    min-width: 200px;
}

.newsArt_thumb_video_basic_img {
    object-fit: cover;
    height: 105px;
    width: 200pxpx;
    max-width: 200px;
    min-width: 200px;
}

.newsArt_thumb_image_basic {
    border-right: 1px solid;
    background: #ffffff;
    border-color: #cccccc;
    height: 105px;
    width: 105px;
    max-width: 105px;
    min-width: 105px;
}

.newsArt_thumb_image_basic_img {
    object-fit: cover;
    height: 105px;
    width: 105px;
    max-width: 105px;
    min-width: 105px;
}

.basic_listing_info_box_desc {
    overflow: hidden;
    height: 25px;
    margin-bottom: 5px;
    font-weight: 500;
}

.viewed_mark {
    position: absolute;
    right: 5px;
    bottom: 8px;
    color: #138808;
    background: white;
    border-radius: 50%;
    border: solid 1px #fff;
}

.viewed_right {
    right: 20px;
}

.newsArt_Desc {
    padding: 7px !important;
}

.newsArt_link {
    /*cursor: pointer;*/
    width: fit-content;
    font-size: 1.20rem;
    color: #FF9933;
}

.newsArt_link_video {
    /*cursor: pointer;*/
    width: fit-content;
    font-size: 1.20rem;
    color: #FF9933;
}

.listing_marginBottom {
    margin-bottom: 10px;
}

/*********************************************/

@media screen and (min-device-width: 360px) and (max-device-width: 567px) {
    .basic_listing_info:nth-child(even) {
        /* padding-left: 0px; */
        /* padding-left: 17px; */
    }

    .basic_listing_info:nth-child(odd) {
        /* padding-right: 0px; */
        /* padding-left: 17px; */
    }

    .listing_info_box_basic {
        min-width: 336px;
        width: 336px;
    }

    .basic_listing_info_box_desc {
        font-size: .6rem;
        height: 45px;
    }

    .viewed_mark {
        position: absolute;
        right: 2px;
        bottom: 4px;
    }

    .newsArt_thumb_video_basic {
        border-right: 1px solid;
        background: #ffffff;
        border-color: #cccccc;
        height: 105px;
        width: 170px;
        max-width: 170px;
        min-width: 1700px;
    }
    
    .newsArt_thumb_video_basic_img {
        object-fit: cover;
        height: 105px;
        width: 170px;
        max-width: 170px;
        min-width: 170px;
    }

    .newsArt_thumb_image_basic {
        border-right: 1px solid;
        background: #ffffff;
        border-color: #cccccc;
        min-width: 105px;
        height: 105px;
        width: 105px;
        max-width: 105px;
    }

    .newsArt_thumb_image_basic_img {
        object-fit: fill;
        height: 105px;
        width: 105px;
        max-width: 105px;
        min-width: 105px;
    }

    /* .newsArt_Desc {
        padding: 7px !important;
    } */

    .newsArt_link {
        /*cursor: pointer;*/
        font-size: 14px;
    }

    .viewed_mark_home {
        position: absolute !important;
        bottom: 6px !important;
        color: #138808;
        background: white;
        border-radius: 50%;
        border: solid 1px #fff;
    }

    .listing_info_box_basic_home {
        border: 1px solid;
        height: 131px !important;
        margin-bottom: 10px;
        margin-left: .25rem;
        margin-right: .25rem;
        background: #ffffff;
        border-color: #cccccc;
    }

    .home_thumb_image_basic {
        border-right: 1px solid;
        background: #ffffff;
        border-color: #cccccc;
        height: 130px !important;
        width: 130px !important;
        max-width: 130px !important;
        min-width: 130px !important;
    }
    
    .home_thumb_image_basic_img {
        object-fit: cover;
        height: 130px !important;
        width: 130px !important;
        max-width: 130px !important;
        min-width: 130px !important;
    }

    .home_Desc {
        padding: 0px !important;
        margin-left: 0px !important;
    }

    .home_link {
        /*cursor: pointer;*/
        width: fit-content;
        font-size: 0.8rem !important;
        color: #FF9933;
        line-height: 14px;
        padding-left: 10px !important;
    }
    
    .basic_home_listing_info_box {
        overflow: hidden;
        margin-bottom: 5px;
        font-size: 10px !important;
        line-height: 12px;
        padding-left: 10px !important;
    }
    
    .basic_home_listing_info_box_desc {
        overflow: hidden;
        height: 25px;
        margin-bottom: 5px;
        line-height: 14px;
        font-weight: 700;
        font-size: 10px !important;
        padding-left: 10px !important;
        font-style: italic;
    }

/* Home Video Card CSS */

    .listing_card_video {
        /* width: 320px !important; */
        height: 275px;
        margin: 3px;
    }
    
    .mob_listing_title_video {
        /*overflow: hidden;*/
        font-size: 0.9rem;
        margin-bottom: 2px !important;
    }
    
    .listing_info_box_desc_newsArt_video {
        font-size: 0.8rem !important;
    }
    
    .basicVideoSubCat {
        font-size: 12px !important;
    }
}

@media screen and (min-device-width: 568px) and (max-device-width: 639px) {
    .basic_listing_info:nth-child(even) {
        /* padding-left: 28px; */
    }

    .basic_listing_info:nth-child(odd) {
        /* padding-right: 0px; */
        /* padding-left: 28px; */
    }

    .listing_info_box_basic {
        min-width: 516.5px;
        width: 516.5px;
    }

    .basic_listing_info_box_desc {
        font-size: .7rem;
        height: 32px;
    }

    .viewed_mark {
        position: absolute;
        right: 2px;
        bottom: 4px;
    }

    .newsArt_thumb_image_basic {
        border-right: 1px solid;
        background: #ffffff;
        border-color: #cccccc;
        height: 105px;
        min-width: 105px;
        max-width: 105px;
        width: 105px;
    }

    .newsArt_thumb_image_basic_img {
        object-fit: fill;
        height: 105px;
        min-width: 105px;
        width: 105px;
        max-width: 105px;
    }

    .newsArt_Desc {
        padding: 10px !important;
    }

    .newsArt_link {
        /*cursor: pointer;*/
        font-size: 1rem;
    }

    /* Home Video Card CSS */

    .listing_card_video {
        /* width: 320px !important; */
        margin-bottom: 5px !important;
    }
    
    .mob_listing_title_video {
        /*overflow: hidden;*/
        font-size: 0.9rem;
        margin-bottom: 2px !important;
    }
    
    .listing_info_box_desc_newsArt_video {
        font-size: 0.8rem !important;
    }
    
    .basicVideoSubCat {
        font-size: 12px !important;
    }
}

@media screen and (min-device-width: 640px) and (max-device-width: 767px) {
    .basic_listing_info:nth-child(even) {
        /* padding-left: 12px; */
        /* padding-right: 0px; */
    }

    .basic_listing_info:nth-child(odd) {
        /* padding-right: 0px; */
        /* padding-left: 12px; */
    }

    .listing_info_box_basic {
        min-width: 588px;
        width: 588px;
    }

    .basic_listing_info_box_desc {
        font-size: .8rem;
        height: 35px;
    }

    .viewed_mark {
        position: absolute;
        right: 2px;
        bottom: 4px;
    }

    .newsArt_thumb_image_basic {
        border-right: 1px solid;
        background: #ffffff;
        border-color: #cccccc;
        height: 120px;
        min-width: 120px;
        max-width: 120px;
        width: 120px;
    }

    .newsArt_thumb_image_basic_img {
        object-fit: fill;
        height: 120px;
        width: 120px;
        min-width: 120px;
        max-width: 120px;
    }

    .newsArt_Desc {
        padding: 10px !important;
    }

    .newsArt_link {
        /*cursor: pointer;*/
        font-size: 1.2rem;
    }

    .listing_card_video {
        /* width: 285px !important; */
        margin-bottom: 5px !important;
    }

    .listing_thumb_image_video img {
        object-fit: fill;
        /* width: 285px !important;
        min-height: 150px !important;
        max-height: 150px !important; */
    }
    
    .mob_listing_title_video {
        /*overflow: hidden;*/
        font-size: 0.9rem;
        margin-bottom: 2px !important;
    }
    
    .listing_info_box_desc_newsArt_video {
        font-size: 0.8rem !important;
    }
    
    .basicVideoSubCat {
        font-size: 12px !important;
    }
}

@media screen and (min-device-width: 768px) and (max-device-width: 999px) {
    .basic_listing_info:nth-child(even) {
        /* padding-left: 8px; */
        min-width: 725px;
        /* padding-right: 0px; */
    }

    .viewed_mark_home {
        position: absolute;
        bottom: 5px !important;
        color: #138808;
        background: white;
        border-radius: 50%;
        border: solid 1px #fff;
    }
    
    .viewed_right_home {
        right: 20px !important;
    }

    .basic_listing_info:nth-child(odd) {
        /* padding-right: 0px;
        padding-left: 8px; */
        min-width: 725px;
    }

    .listing_info_box_basic {
        min-width: 725px;
        width: 725px;
    }

    .basic_listing_info_box_desc {
        font-size: .8rem;
        height: 65px;
    }

    .viewed_mark {
        position: absolute;
        right: 2px;
        bottom: 4px;
    }

    .newsArt_thumb_image_basic {
        border-right: 1px solid;
        background: #ffffff;
        border-color: #cccccc;
        height: 152px;
        min-width: 152px;
        max-width: 152px;
        width: 152px;
    }

    .newsArt_thumb_image_basic_img {
        object-fit: fill;
        height: 152px;
        width: 152px;
        min-width: 152px;
        max-width: 152px;
    }

    .newsArt_Desc {
        padding: 10px !important;
    }

    .newsArt_link {
        /*cursor: pointer;*/
        font-size: 1.2rem;
    }

    .listing_card_video {
        /* width: 320px !important; */
        margin-bottom: 5px !important;
    }
    
    .mob_listing_title_video {
        /*overflow: hidden;*/
        font-size: 0.9rem;
        margin-bottom: 2px !important;
    }
    
    .listing_info_box_desc_newsArt_video {
        font-size: 0.8rem !important;
    }
    
    .basicVideoSubCat {
        font-size: 12px !important;
    }
}

@media screen and (min-device-width: 1000px) and (max-device-width: 1279px) {
    .basic_listing_info:nth-child(even) {
        /* padding-left: 1px; */
    }

    .basic_listing_info:nth-child(odd) {
        /* padding-right: 1px; */
    }

    .listing_info_box_basic {
        min-width: 480px;
        width: 480px;
    }

    .basic_listing_info_box_desc {
        font-size: .8rem;
        height: 65px;
    }

    .viewed_mark {
        position: absolute;
        right: 2px;
        bottom: 4px;
    }

    .newsArt_thumb_image_basic {
        border-right: 1px solid;
        background: #ffffff;
        border-color: #cccccc;
        height: 152px;
        min-width: 152px;
        max-width: 152px;
        width: 152px;
    }

    .newsArt_thumb_image_basic_img {
        object-fit: fill;
        height: 152px;
        width: 152px;
        min-width: 152px;
        max-width: 152px;
    }

    .newsArt_Desc {
        padding: 10px !important;
        padding-left: 40px !important;
    }

    .newsArt_link {
        /*cursor: pointer;*/
        font-size: 1.2rem;
    }

    .listing_card_video {
        /* width: 312px !important; */
        margin-bottom: 5px !important;
    }

    .listing_thumb_image_video img {
        object-fit: fill;
        /* width: 312px !important; */
        /* min-height: 164px !important;
        max-height: 164px !important; */
    }
    
    .mob_listing_title_video {
        /*overflow: hidden;*/
        font-size: 0.9rem;
        margin-bottom: 2px !important;
    }
    
    .listing_info_box_desc_newsArt_video {
        font-size: 0.8rem !important;
    }
    
    .basicVideoSubCat {
        font-size: 12px !important;
    }
}

@media screen and (min-device-width: 1280px) and (max-device-width: 1326px) {
    .basic_listing_info:nth-child(even) {
        /* padding-left: 1px; */
    }

    .basic_listing_info:nth-child(odd) {
        /* padding-right: 1px; */
    }

    .listing_info_box_basic {
        min-width: 602.5px;
        width: 602, 5px;
    }

    .basic_listing_info_box_desc {
        font-size: .8rem;
        height: 65px;
    }

    .viewed_mark {
        position: absolute;
        right: 2px;
        bottom: 4px;
    }

    .newsArt_thumb_image_basic {
        border-right: 1px solid;
        background: #ffffff;
        border-color: #cccccc;
        height: 152px;
        min-width: 152px;
        max-width: 152px;
        width: 152px;
    }

    .newsArt_thumb_image_basic_img {
        object-fit: fill;
        height: 152px;
        width: 152px;
        min-width: 152px;
        max-width: 152px;
    }

    .newsArt_Desc {
        padding: 10px !important;
    }

    .newsArt_link {
        /*cursor: pointer;*/
        font-size: 1.2rem;
    }
    
    .listing_card_video {
        /* width: 320px !important; */
        margin-bottom: 5px !important;
    }
    
    .mob_listing_title_video {
        /*overflow: hidden;*/
        font-size: 0.9rem;
        margin-bottom: 2px !important;
    }

    .listing_thumb_image_video img {
        /* width: 395px !important;
        min-height: 140px;
        max-height: 140px; */
    }
    
    .listing_info_box_desc_newsArt_video {
        font-size: 0.8rem !important;
    }
    
    .basicVideoSubCat {
        font-size: 12px !important;
    }
}

/**********************Basic Video HomePage Cards**************************/

.listing_info_box_basic_home {
    /* border: 1px solid; */
    height: auto;
    margin-bottom: 10px;
    margin-left: .25rem;
    margin-right: .25rem;
    /* background: #ffffff; */
    /* border-color: #cccccc; */
}

.home_thumb_image_basic {
    /* border-right: 1px solid; */
    background: #ffffff;
    border-color: #cccccc;
    /* height: 200px;
    width: 200px;
    max-width: 200px;
    min-width: 200px; */
}

.home_thumb_image_basic_img {
    object-fit: cover;
    /* height: 200px;
    width: 200px;
    max-width: 200px; */
    /* min-width: 200px; */
}

.home_Desc {
    padding: 7px !important;
    background-color: white;
}

.home_link {
    /*cursor: pointer;*/
    width: fit-content;
    font-size: 1.20rem;
    color: #FF9933;
    /* padding-left: 95px; */
}

.basic_home_listing_info_box {
    overflow: hidden;
    margin-bottom: 5px;
    font-size: 14px;
    /* padding-left: 95px; */
}

.basic_home_listing_info_box_desc {
    overflow: hidden;
    height: 25px;
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 14px;
    /* padding-left: 95px; */
    font-style: italic;
}

.name_span {
    font-style: normal;
}

.viewed_mark_home {
    position: absolute;
    right: 5px;
    bottom: 8px;
    color: #138808;
    background: white;
    border-radius: 50%;
    border: solid 1px #fff;
}

.viewed_right_home {
    right: 20px;
}

/* Home Video Card CSS */

.listing_card_video {
    position: relative;
    overflow: hidden;
    /* width: 320px; */
    height: 350px;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    /* width: fit-content; */
    /* max-width: 320px; */
    background-color: #ffffff;
}

.listing_thumb_image_video img {
    object-fit: fill;
    /* width: 320px;
    min-height: 168px;
    max-height: 168px; */
    border: 1px solid #cccccc;
    /* border-radius: 15px; */
}

.listing_title_video {
    /*overflow: hidden;*/
    font-size: 1.25rem;
    font-weight: 600;
    /* margin-bottom: 8px; */
    color:#FF9933;
}

.clip_title_video {
    /*overflow: hidden;*/
    font-size: 1rem;
    margin-bottom: 0px;
    color:#FF9933;
    font-weight: 600;
}

.mob_listing_title_video {
    /*overflow: hidden;*/
    font-size: 1.05rem;
    color:#FF9933;
    padding-bottom: 10px;
    font-weight: 600;
}

.listing_info_box_desc_newsArt_video {
    /*overflow: hidden;*/
    font-size: 0.9rem;
    padding-bottom: 10px;
}

.basicVideoSubCat {
    overflow: hidden;
    font-weight: 700;
    font-size: 12px;
    font-style: italic;
    color: #333333;
}

.basicVideoSubCatClip {
    overflow: hidden;
    font-weight: 700;
    font-size: 10px;
    padding-top: 4px;
    font-style: italic;
    color: #333333;
}