footer {
    padding: 0.2px;
    width: 100%;
    background: #e5e5f2;
    overflow-x: hidden;
}

.footer li:last-child {
    border: none;
}

.siteFooter {
    width: 77%;
}

.siteFooterCenter {
    width: 100%;
}



.footer li {
    border-right: 1px solid;
    padding: 0 10px;
    margin: auto;
    line-height: normal;
}

.footerLink {
    text-decoration: underline;
}

.footerLink,
li,
.footerLink:hover {
    color: #333333;
}

.link {
    text-decoration: underline;
    font-style: italic;
}

.logo_saffron {
    color: #FF9933;
    font-weight: 700;
}

.logoName {
    font-size: 36px;
}

.logo_green {
    color: #138808;
    font-weight: 700;
}

.logo_blue {
    color: #000080;
    font-weight: 700;
}

.board {
    display: inline-flex;
    justify-content: center;
    text-align: center;
}

.board_padding {
    padding: 10px;
}

.img_display {
    display: inline-flex;
}

.img_paddingRight {
    padding-right: 25px;
}

.img_padding {
    padding-left: 25px;
    padding-right: 25px;
}

.advt_font {
    font-weight: bold;
}

.advt_video {
    width: 90%;
    border: 2px solid #4d4c4c;
}

.advt_video_edit{
    width: 70%
}

.margin {
    margin-left: -22px;
}

/*****************************************/



@media screen and (min-width: 1001px) {
    .grid_container {
        padding: 7%;
        background-color: #fff;
    }

    .siteFooterWrap {
        margin: 8px auto;
    }

    .board_padding {
        padding: 40px;
    }
}

@media screen and (max-width: 736px) {
    .grid_container {
        word-wrap: break-word;
    }

    .annual {
        font-size: 0.8em;
    }

    .annual_dark {
        font-size: 0.8em;
    }

    .half_year {
        font-size: 0.8em;
    }

    .half_dark {
        font-size: 0.8em;
    }

    .monthly {
        font-size: 0.8em;
    }

    .monthly_dark {
        font-size: 0.8em;
    }

    .postpaid {
        font-size: 0.8em;
    }

    .postpaid_dark {
        font-size: 0.8em;
    }

}

.pageHeading {
    /* margin-top: .5em; */
    font-size: 2.8em;
    font-weight: 700;
}

.sectionHeading {
    margin-top: .5em;
    font-size: 2.25em;
    font-weight: 700;
    color: #FF9933;
}

.paraHeading {
    margin-top: .5em;
    font-size: 1.68em;
    font-weight: 700;
    text-align: left;
}

.spanCenter {
    font-size: 2.25em;
    color: #000080;
}

.paragraph {
    margin-top: .5em;
    font-size: 1.5em;
}

.highlighter {
    font-size: 2.25em;
    color: #000080;
    text-align: center;
}

.abtH2 {
    font-size: 30px;
    color: #FF9933;
    font-weight: 600;
}

@media screen and (max-width: 1326px) {
    .siteFooter {
        width: 100%;
    }
}

@media screen and (min-width: 1001px) {
    .right_side_btn {
        margin-left: 30%;
    }
}

.line {
    border: 1px dashed black;
}

.overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.playIcon {
    font-size: 60px;
    color: #FF9933;
    -webkit-text-stroke: 2px #138808;
    opacity: 1;
}

.linkPoint {
    font-size: 20px;
    color: #FF9933;
    -webkit-text-stroke: 1px #138808;
    opacity: 1;
}

.success_story {
    width: 650px;
    height: auto;
}

.aaju {
    color: #FF9933;
}

.baaju {
    color: #138808;
}

.ians {
    color: #000080;
}

.boxes {
    border: 2px solid black;
    padding: 8px
}

.bold_text {
    font-weight: 600;
}

.annual_head {
    background-color: #FF9933;
    color: white;
    font-weight: 700;
    border: 5px solid white;
}

.half_head {
    background-color: #138808;
    color: white;
    font-weight: 700;
    border: 5px solid white;
}

.monthly_head {
    background-color: #000080;
    color: white;
    font-weight: 700;
    border: 5px solid white;
}

.postpaid_head {
    background-color: #4a2f15;
    color: white;
    font-weight: 700;
    border: 5px solid white;
}

.annual {
    background-color: #ffe6cc;
    border: 5px solid white;
    font-weight: 600;
}

.annual_dark {
    background-color: #fac58d;
    border: 5px solid white;
    font-weight: 600;
}

.half_year {
    background-color: #e6ffe6;
    border: 5px solid white;
    font-weight: 600;
}

.half_dark {
    background-color: #b1f1b1;
    border: 5px solid white;
    font-weight: 600;
}

.monthly {
    background-color: #cce6ff;
    border: 5px solid white;
    font-weight: 600;
}

.monthly_dark {
    background-color: #68b0f9;
    border: 5px solid white;
    font-weight: 600;
}

.postpaid {
    background-color: #e6d9b3;
    border: 5px solid white;
    font-weight: 600;
}

.postpaid_dark {
    background-color: #c0ae7b;
    border: 5px solid white;
    font-weight: 600;
}