.select_package {
    width: fit-content;
    text-align: center;
    margin: auto;
    justify-content: center;
}

.select_input[type="radio"]:checked+.box_footer {
    color: #000080;
}

.select_input[type="radio"] {
    display: none;
}

.select_input[type="radio"]:checked+.box_span {
    transform: translateY(70px);
}

.select_input[type="radio"]:checked+.box_span:after+.box_footer:after {
    transform: translateY(0px);
    opacity: 1;
}

.box {
    width: 255px;
    transition: all 250ms ease;
    will-change: transition;
    display: inline-block;
    text-align: center;
    font-weight: 500;
    border: 1px solid;
    margin-left: 5px;
    margin-right: 5px;
    top: 0;
    bottom: 0;
    border-color: #000080;
    background: #ffffff;
}

.box:active {
    transform: translateY(10px);
}

.box_span {
    line-height: 10px;
    position: relative;
    transform: translate(0, 60px);
    left: 0;
    right: 0;
    transition: all 300ms ease;
    font-size: 1.2em;
    user-select: none;
}

.box_span:before {
    font-size: 1.2em;
    font-family: FontAwesome;
    display: block;
    transform: translateY(-80px);
    opacity: 0;
    transition: all 300ms ease-in-out;
    font-weight: normal;
}

.box_span:before {
    content: '\f16b';
}

.box_span{
    color: #000080;
}

.select_input[type="radio"]:checked+.box {
    background: #000080;
}

.select_package h1, .select_package input[type="radio"]:checked+.box span, .select_package .box span:before {
    color: #ffffff;
}

.box_header {
    padding: 5px;
    background: #000080;
    color: #ffffff;
}

.box_footer {
    padding: 15px;
    color: #ffffff;
    width: 254px;
    cursor: pointer;
    background: #000080;
}