.title_heading {
    margin-top: .5em;
    font-size: 2.25em;
    font-weight: 700;
    color: #FF9933;
}

@media screen and (max-width: 700px) {
    .title_heading {
        padding-top: 2rem;
    }
}

@media screen and (max-width: 567px) {
    .title_heading {
        padding-top: 3rem;
    }
}