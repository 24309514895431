.btn_link, .btn_link:hover, .btn_link:focus, .btn_link:active {
    font-size: unset;
    font-weight: unset;
    padding: 0;
    text-decoration: underline;
    margin-bottom: 3px;
}

.btn_link span {
    text-decoration: inherit;
}

.spacing {
    letter-spacing: 1px;
}

.submit_btn {
    width: 85px;
    font-weight: unset;
}

.update_mobile {
    color: #1890ff;
    text-decoration: none;
    margin-top: -2px;
}

.resend_otp_btn {
    color: #1890ff;
    text-decoration: none;
}

.white_space {
    white-space: nowrap;
}

.email_btn {
    width: 120px;
    font-weight: unset;
}

.otp_header {
    padding-top: 45px;
}

.Registration {
    /* border: 2px solid; */
    padding-top: 3%;
    border-color: #cccccc;
    background: #e5e5f2;
}

.AboutUsCon {
    background: #e5e5f2;
}

.AboutUs {
    background-color: rgb(229, 241, 228);
    margin: 10px;
    margin-top: 6%;
    padding: 20px;
}

.RegistrationMob {
    padding-top: 2%;
    margin-left: -2%;
    background: #e5e5f2;
}

.otp_form, .post_section {
    padding: 10% 14%;
}

.reg_form_section {
    padding: 3% 10%;
}

.btn_link, .btn_link:hover {
    border: none;
    background: none;
}

.registration_header, .otp_header {
    text-align: center;
}

.resend_otp {
    margin-top: 15px;
}

.post_section_p {
    font-size: 0.9375rem;
    font-weight: 500;
    text-align: center;
}

.registration_header {
    color: #138808;
}

.btn_link, .btn_link:hover, .btn_link:focus {
    color: #000080;
}

.classified_add {
    margin-top: 15px;
}

.reg_form_section_label {
    font-weight: 600;
    margin-top: 5px;
    color: #000080;
}

.reg_form_section_label_mobile {
    font-weight: 600;
    margin-top: 2px;
    color: #000080;
    margin-left: 12px;
}

.form_check {
    margin-top: 15px;
}

.form_check_input {
    margin: auto 10px auto auto;
    position: relative;
    cursor: pointer;
}

.reg_button {
    margin: 15px 0;
}

.account_register {
    margin: 30px 0;
}

.otp_section {
    margin: auto;
    text-align: left;
}

.redirect_link {
    color: #FF9933;
}

.error {
    color: #FF0000;
    text-align: center;
}

.regis_content_border {
    border: solid 3px;
    border-radius: 30px;
    padding: 3%;
    /* padding: 5px; */
}