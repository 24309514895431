.tr_lineHeight {
    line-height: 2px;
}

.td_width {
    width: 2%;
}

.posted_width {
    width: 9%;
}

.td_ad_width {
    width: 8%;
}

.ad_container {
    padding: 5px;
}

.th_title {
    word-break: break-all;
    width: 30%;
}

.th_li_title {
    word-break: break-all;
    width: 38%;
}

.ad_marginTop {
    margin-top: 10vh;
}

.date_left {
    left: -40px;
}

.mobile_date_left {
    left: 20px;
}

.btn_font {
    font-weight: unset;
}

.li_width {
    width: 6%;
}

.li_date {
    width: 16%;
}

.td_li_width {
    width: 10%;
}

.tr_marginTop {
    margin-top: 10px;
}

.name_width {
    width: 50%;
}

.joindate_width {
    width: 11%;
}

.account_width {
    width: 17%;
}

.user_width {
    width: 25%;
}

.title_style {
    margin-top: 5vh;
}

.report_activity {
    word-break: break-all;
    width: 15.5%;
}

.report_count {
    text-align: center;
    width: 6%;
}

.report_earning {
    text-align: center;
    width: 7%;
}

.outer_container {
    margin: 30px;
}

.view_table_margin {
    margin-bottom: 5px;
}

.p_expiryDate {
    padding-left: 2px;
    font-weight: 600;
}

.p_monthlyTarget {
    padding-top: 10px;
    padding-left: 2px;
    font-weight: 600;
    font-size: 12px;
}

.dashboard {
    margin-left: 25px;
}

.date_range_style {
    margin: 20px 0 5px 25px;
}

.mobile_date_range_style {
    margin: 20px 0 5px 40px;
}

.display_table {
    margin: 0 30px 30px 30px;
}

.popup_marginLeft {
    margin-left: 10px;
}

.manage_ad_width {
    width: 7%;
}

.manage_display {
    display: inline;
}

.manage_right {
    right: 40px;
}

.u_marginBottom {
    margin-bottom: 0px;
}

.manage_action {
    padding-left: 5px;
}

.manage_paddingTop {
    padding-top: 25px;
}

.mobile_manage {
    margin: 10px;
    margin-top: 50px;
    font-size: 14px;
    text-align: center;
}

.manage_td_width {
    width: 3%;
}

.manage_ad_name {
    width: 47%;
}

.manage_action_list {
    right: 25px;
}

.manage_th_title {
    word-break: break-all;
    width: 37%;
}

.redirect_link_decor {
    text-decoration: underline;
}

.tr_contri {
    height: 50px;
}

.des_width {
    width: 70%;
}

.upload_cursor {
    cursor: pointer;
}

.image_i {
    height: 105px;
    width: 105px;
    font-size: 7.5em;
}

.btn_upgrade {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}

.lable_user_data_margin {
    margin-left: 5px;
}

.order_width {
    width: 20%;
}

.order_width_id {
    width: 15%;
}

.order_width_tax {
    width: 5%;
}

.order_marginBottom {
    margin-bottom: 50px;
}

.order_marginRight {
    margin-right: 1rem;
}