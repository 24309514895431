.btn_warning, .btn_warning:hover {
    border-color: #FF9933;
    color: #ffffff;
    background: #FF9933;
    font-weight: bold;
}

.btn_warning:active {
    color: #000;
}
