/********************AdminHeader*******************/
.navbar_sticky {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 20;
}

.middle_strip {
    padding: 2px 0 -12px;
}

.grid_container {
    padding: 0 2%;
}

.right_side_btn {
    text-align: center;
}

.header_link {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    -webkit-transition: color .3s;
    transition: color .3s;
    font-size: 1.01rem;
    background: transparent;
    padding: 0;
}

.header_link_help {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    -webkit-transition: color .3s;
    transition: color .3s;
    font-size: 1.01rem;
    background: transparent;
    color: #000080;
    padding: 0;
}

.right_list {
    border-right: 1px solid #cccccc;
    padding: 0 18px 12px 0;
    margin: auto;
    line-height: normal;
}

.right_list_help {
    padding-left: 10px;
}

/**********************AdminPanel*****************/

.admin_panel {
    display: flex;
    min-height: 100vh;
}

.admin_tab {
    float: left;
    padding-top: 1rem;
    /* position: fixed; */
    border-right: 1px solid #000080;
}

.admin_content {
    float: left;
    padding-top: .5rem;
    background: #fffaf5;
}

.user_td {
    width: 10%;
}

.state_td {
    width: 12%;
}

.city_td {
    width: 12%;
}

.locality_td {
    width: 12%;
}

.neightbourhood_td {
    width: 13%;
}

.action_td {
    width: 7%;
}

/* Add this for scroll notice CSS file */
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 28px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
