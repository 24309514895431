.freez {
    position: fixed;
    top: 130px;
}

@media only screen and (max-width: 1366px) {
    .freez {
        top: 50px;
    }
}

.largeRectangleAd {
    width: 336px;
    height: 280px;
    margin: auto auto 2px auto;
    background: #000080;
}

.ad_position {
    position: relative;
}

.largeRectAd {
    display: block;
    width: 336px;
    height: 280px;
    margin: auto auto 10px auto;
    background: #000080;
    }

    @media screen and (min-width: 0px) and (max-width: 400px) {
        .largeRectAd{ display: none; }  /* show it on large screen */
      }
      @media screen and (min-width: 401px) and (max-width: 1024px) {
        .largeRectAd{ display: block; }   /* hide it small screens */
      }