.header_link {
    text-decoration: none !important;
    outline: none;
    cursor: pointer;
    -webkit-transition: color .3s;
    transition: color .3s;
    font-size: .875rem;
    padding: 0;
    color: #000080;
    background: transparent;
}

button {
    text-decoration: none;
}