.navbar_sticky {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 20;
}

.top_strip {
    -moz-box-shadow: 0 -2px 0 0 rgba(0, 0, 0, 0.07) inset;
    -webkit-box-shadow: 0 -2px 0 0 rgba(0, 0, 0, 0.07) inset;
    box-shadow: 0 -2px 0 0 rgba(0, 0, 0, 0.07) inset;
    outline: none;
}

.top_strip {
    background: #FF9933;
    color: #ffffff;
}

.grid_container {
    padding: 0 2%;
}

.middle_strip {
    padding: 5px 0px;
}
.avatar {
    z-index: 999;
    position: sticky;
}

.post_help {
    margin-top: 7px;
    margin-left: -7%;
}

.header_link {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    -webkit-transition: color .3s;
    transition: color .3s;
    font-size: .875rem;
    padding: 0;
}

.contact_link a,
.contact_link a:hover {
    color: #ffffff;
}

.text_right {
    text-align: right;
}

.right_side_btn {
    text-align: center;
}

.right_list {
    border-right: 1px solid #cccccc;
    padding: 0 10px 0 0px;
    margin: auto;
    line-height: normal;
}

.header_link {
    color: #000080;
    background: transparent;
}

.post_ad_btn {
    padding: 0 5px;
}

.search_button_col {
    padding: 0 5px;
}

.upgrade_link {
    text-decoration: underline;
    color: #fff;
}

.header_text {
    text-decoration: underline;
}

/**************************MobileView*************************/

.navbar_sticky_mobile {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 20;
    position: relative;
}

.middle_strip_mobile {
    margin-left: 18px;
}

.right_list_mobile_login {
    border-right: 1px solid #cccccc;
    padding: 0 8px 0 2px;
    margin: auto;
    line-height: normal;
}

.right_list_mobile {
    padding: 0 3px 0 2px;
    margin: auto;
    line-height: normal;
}

.right_list_help_mobile {
    padding: 0 -2px 0 0px;
    margin: auto;
    line-height: normal;
}

.logo_mob {
    margin-top: -4px;
    margin-bottom: -10px;
}

.post_help_mobile {
    margin-top: -4px;
    margin-left: 15px;
}

.post_ad_btn_mobile {
    margin-left: -4px;
    margin-top: -12px;
}

.search_button_col_mobile {
    margin-left: 12px;
}

.post_ad_btn_mobile button {
    font-size: 9px;
    width: 72px;
    height: 25px;
}

.left {
    float: left;
}

.right {
    float: right;
}

.cursor_mob {
    cursor: pointer;
    padding-right: -2px;
}

.right_list_loginmobile {
    padding: 0 12px 0 8px;
    margin: auto;
    line-height: normal;
}

.avatar_loginmobile {
    padding: 0 2px 0 4px;
    margin: auto;
    line-height: normal;
}

.right_list_help_loginmobile {
    padding: 0 8px 0 0px;
    margin: auto;
    line-height: normal;
}

.post_help_loginmobile {
    margin-top: -2px;
    margin-left: 4px;
}

.post_ad_btn_loginmobile {
    margin-left: -7px;
    margin-top: -12px;
}

.post_ad_btn_loginmobile button {
    font-size: 10px;
    width: 72px;
    height: 26px;
}

.loginbtn {
    margin-top: -10px;
    margin-right: -5px;
}
