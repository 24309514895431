.addpost_section {
    margin-top: 0;
}

.addpost_section [class*="-menu"] {
    z-index: 9;
}

.error {
    color: #FF0000;
    text-align: center;
}

.hide {
    display: none;
}

.addInfo {
    text-align: center;
    font-weight: bold;
    margin-bottom: 15px;
    color: #138808;
}

.add_new {
    background: #e5e5f2;
    padding-top: 10px;
    padding-right: 25px;
    margin-bottom: 15px
}

.website_input {
    padding-left: 50px;
}

.website_prefix_label:before {
    content: 'www.';
    position: absolute;
    top: -1px;
    bottom: 0;
    z-index: 3;
    display: block;
    padding: 0.525rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
}

/**************************************/

/************listingAdvance***********/
.modal {
    font-size: 0.75rem;
    position: relative;
    display: block;
    overflow: visible;
    /* to make large number of selected address visible */
}

.modal_header {
    width: 100%;
    border-bottom: 1px solid;
    font-size: 1.125rem;
    text-align: center;
    padding: 5px;
    border-color: #cccccc;
    background: #fffaf5;
}

.modal_content {
    width: 100%;
    padding: 10px 5px;
    background: #fffaf5;
}

.modal_close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 5px;
    line-height: 8px;
    right: 0;
    top: 0;
    font-size: 0.9375rem;
    border-radius: 18px;
    border: 1px solid;
    border-color: #cccccc;
    background: #ffffff;
}

.basic_date_time {
    width: 100% !important;
    margin-right: 10px !important;
}

.listing_preview {
    margin-top: 2.4rem;
    background-color: #fff;
}

.listing_preview_basic {
    margin-top: 1rem;
    display: flex;
}

.preview_header_padding {
    margin-right: 2px;
}

.image_container {
    width: 100%;
    max-width: 350px;
    max-height: 350px;
    min-width: 350px;
    min-height: 350px;
}

.image_container_event {
    width: 100%;
    max-width: 350px;
    max-height: 350px;
    min-width: 350px;
    min-height: 350px;
}

.thumb_image {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    max-width: 350px;
    max-height: 350px;
    min-width: 350px;
    min-height: 350px;
    width: 100%;
}

.classified_add {
    margin-top: 15px;
}

.featured_input_header {
    font-size: x-large;
    font-weight: bold;
    padding: 0;
    background: #ffffff;
}

input.featured_input:focus {
    border: none;
}

.preview_header {
    color: #138808;
    font-weight: 500;
    margin-bottom: .25rem;
    margin-top: .5rem;
}

.preview_header_event {
    color: #138808;
    font-weight: 500;
    margin-bottom: .25rem;
    margin-top: .5rem;
}

.preview_header_date {
    margin-left: 15px;
}

.banner_min_height {
    height: 350px;
}

.success {
    color: #138808;
    text-align: center;
}

.menu {
    color: #000080;
    font-weight: 600;
    width: 150px;
    margin-top: .2rem;
    text-align: right;
}

.btnMargin {
    margin-right: 1rem;
}

.btnMarginLeft {
    margin-left: 10px;
}

.btnHeight {
    height: 38px;
}

.uploadBtnPadding {
    padding: 4px;
}

.map {
    width: 100%;
    height: 350px;
}

.listing_wordBreak {
    word-break: break-word;
}

.listing_margin {
    margin: 4px;
}

.title_marginTop {
    margin-top: 35px;
}

.title_marginBottom {
    margin-bottom: 25px;
}

/*****************************************/

/***************listingBasic*************/
.article_data {
    margin: 20px 25px;
    padding: 2px;
    line-height: 1.8rem;
}

.article_data_h2 {
    margin-bottom: 0px;
    letter-spacing: 1px;
    word-spacing: 1px;
    font-size: 1.6em;
}

.article_data_mob_h2 {
    margin-bottom: 30px;
    margin-top: 30px;
    letter-spacing: 1px;
    word-spacing: 1px;
    font-size: 1.6em;
}

.article_data_listing_desc {
    font-size: 1.3em;
    word-spacing: 1px;
    line-height: 1.8rem;
    white-space: break-spaces;
    word-break: break-word;
}

.listing_desc {
    white-space: pre-line;
    letter-spacing: .02em;
    padding-left: 12px;
    word-break: break-word;
    /*white-space: pre-wrap;*/
}

.listing_pages_desc {
    white-space: pre-line;
    letter-spacing: .02em;
    margin-left: 28px;
    margin-top: 10px;
    /*white-space: pre-wrap;*/
}

.listing_desc_article_image,
.listing_desc_article_video {
    margin: auto auto .35rem auto;
    color: #00000073;
    text-align: center;
    font-size: 16px;
    padding-left: 15%;
    padding-right: 15%;
}

.article_image {
    width: auto;
    max-height: 500px;
    padding-left: 15%;
    padding-right: 15%;
    text-align: center;
    margin: .5rem .5rem .25rem .5rem;
}

.article_video {
    width: auto;
    max-height: 500px;
    text-align: center;
    margin: .5rem .5rem .25rem .5rem;
}

/* .clip_video {
    border-radius: 15px;
    text-align: center;
    border-radius: 2px solid blue;
} */

.article_video_mob{
    width: 370px;
    max-height: 500px;
    margin-left: -10px;
}

.article_image_mob {
    width: auto;
    max-height: 32em;
}

.single_listing_header {
    display: inline;
    margin-right: 15px;
    margin-bottom: auto;
    margin-top: .5rem;
    margin-top: .4em;
    color: #FF9933;
    font-size: 1.5rem;
    font-weight: 600;
}

.single_listing_header_pages {
    display: inline;
    margin-left: 28px;
    margin-right: 15px;
    margin-bottom: auto;
    margin-top: .5rem;
    margin-top: .4em;
    color: #FF9933;
    font-size: 1.5rem;
    font-weight: 600;
}

.divide_labels {
    margin-bottom: 10px;
}

.back_publish,
.listing_preview .preview_container .preview_image {
    text-align: right;
}

.back_pad {
    padding-right: 15px;
}

.listing_preview .preview_container .preview_image img,
.listing_preview .preview_container .preview_video video {
    max-width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.listing_preview .preview_container .preview_image img {
    object-fit: cover;
    width: 100%;
}

/*********************************************/

/**********************************************************/

.footer_links_header {
    margin-top: .4em;
}

.footer_links_sub_header {
    font-weight: 500;
    margin-bottom: .25rem;
    margin-top: .5rem;
}

.modal_actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
}

.actions {
    padding: 1px;
    cursor: pointer;
}

.contact_subbtn,
.contact_subbtn:hover {
    font-weight: bold;
    color: #ffffff;
}

.modal_contents {
    overflow-y: auto;
    height: 70vh;
    overflow-x: hidden;
    margin: auto;
    background: #fffaf5;
    padding: 10px 5px;
}

.gallery_control {
    height: 80%;
    margin: 5px;
    padding: .2rem;
}

.change_caption_btn {
    font-size: .8rem;
}

.save_caption {
    padding-bottom: -2px;
    margin-top: 0.5px;
}

.gallery_replace {
    transition: .5s ease;
    transform: translate(5%, -12%);
    max-height: 15px !important;
    width: 120px;
    border-radius: 5px !important;
    border: 0px !important;
}

.gallery_replace span {
    font-size: 12px;
    font-weight: 700;
    bottom: -20%;
    position: absolute;
    top: 22%;
    left: 5%;
}

.upload_replace {
    transform: translate(5%, -12%);
    border-radius: 5px !important;
    border: 0px !important;
}

.listingMob {
    padding-top: 2%;
    margin-left: -6%;
}

@media screen and (max-width: 567px) {
    .image_container {
        width: 100%;
        margin-left: -2px;
        max-width: 340px;
        max-height: 340px;
        min-width: 340px;
        min-height: 340px;
    }

    .image_container_event {
        width: 100%;
        margin-left: 3px;
        max-width: 340px;
        max-height: 340px;
        min-width: 340px;
        min-height: 340px;
    }

    .thumb_image {
        max-width: 340px;
        max-height: 340px;
        min-width: 340px;
        min-height: 340px;
    }

    .preview_header_event {
        color: #138808;
        font-weight: 500;
        margin-bottom: .25rem;
        margin-top: .5rem;
        margin-left: 4px;
    }
}


@media screen and (max-width: 720px) {
    .listing_preview_basic {
        display: table-row;
    }

    .listing_desc {
        white-space: pre-line;
        letter-spacing: .02em;
        padding-left: 2px;
        padding-top: 4px;
        word-break: break-word;
        /*white-space: pre-wrap;*/
    }
}

.large_rect_margin {
    margin-top: -10px;
}

@media screen and (min-device-width: 1000px) {
    .large_rect {
        display: none;
    }

    .billBoard {
        display: block;
    }
}

@media screen and (max-device-width: 999px) {
    .billBoard {
        display: none;
    }

    .single_listing_header_pages {
        display: inline;
        margin-left: 8px;
        margin-right: 5px;
        margin-bottom: auto;
        margin-top: 0;
        color: #FF9933;
        font-size: 1.5rem;
        font-weight: 600;
    }

    .article_data {
        margin: 2px 5px;
        padding: 0;
    }

    .listing_desc_article_image,
    .listing_desc_article_video {
        padding-left: 0;
        padding-right: 0;
        margin: 0;
    }
}

.span_rating {
    position: absolute;
}

.cursor_rating {
    cursor: pointer;
}

.rating_comment {
    margin-top: 5px;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 125px;
    width: 100%;
}

.animated_text {
    margin-left: 10px;
}

.rating_font {
    font-size: 15;
}

.caption {
    font-size: medium;
}

.caption_rating {
    font-size: 18;
}

.caption_rating_mob {
    margin-left: 25px;
}

.edit_btn {
    text-align: right;
}

.animatedText {
    margin-top: 6px;
    margin-left: 15px;
    padding: 6px;
}

.submitBtn {
    margin-top: 5px;
}

.hour_display {
    display: flex;
}

.uploadingText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.limitText {
    text-shadow: 1px 1px #fffaf5;
}

.reportLink {
    color: red;
    font-size: 14px;
    font-weight: 700;
}

.mapContainer {
    max-width: 100%;
    position: relative;
    height: 350px;
}

.reg_form_section_label {
    font-weight: 600;
    margin-top: 15px;
    color: #000080;
    margin-left: 15px;
}