/* user managment form */

.password-box {
    display: flex;
  }
  
  .eyeIcon {
    padding: 0.5rem;
  }
  
  .profilePhoto {
    height: 105px;
    width: 105px;
    background-color: lightcyan;
    border-radius: 50%;
  }
  
  .imageLoded {
    border-radius: 50%;
    background-color: lightcyan;
  }
  
  .profile-page-login-data {
    padding-left: 3rem;
  }
  
  .fixed-value-box {
    margin-top: 0.5rem;
  }
  
  .lable_user_data {
    margin-top: 8px;
  }
  
  .field-icon {
    float: right;
    margin-left: -25px;
    margin-top: -25px;
    margin-right: 10px;
    position: relative;
    z-index: 2;
    cursor: pointer;
  }
  
  .labelAddUpload {
    color: blue;
  }
  
  #profile-photo {
    border-radius: 50%;
  }

  .headingLeftMargin {
    margin-left: 25px;
  }

  .contentLeftPadding {
    padding-left: 30px;
  }

  .adMarginTop {
    margin-top: 10vh;
    margin-left: -1vh;
  }