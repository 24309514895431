/*Avatar style */

.header-avatar {
  /* height:40px;*/
  /*width:40px; */
  border-radius: 50%;
  cursor: pointer;
  margin-top: -5px;
}

.menu-lists, .arrow, .inner-box {
  position: absolute;
  background-color: rgb(231, 207, 245);
  color: darkblue;
  border: 1px solid;
  top: 100%;
  left: -100%;
  width: 140px;
  z-index: 3;
  border-radius: 5%;
  box-shadow: 0px 0px 3px 0px #000000e0;
  padding: 2px;
  animation-name: fadeIn;
  animation-duration: 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.arrow {
  width: 12px;
  height: 12px;
  border-bottom-style: none;
  border-right-style: none;
  transform: rotate(45deg);
  margin-left: 62px;
  margin-top: -8px;
  z-index: 0;
  animation-name: none;
  position: initial;
}

.inner-box {
  position: sticky;
  box-shadow: none;
  margin-top: -7px;
  border: 1px solid transparent;
  width: 105%;
  animation-name: none;
  border-radius: 10px;
}

/* .menu-lists::after {
  width: 0;
  height: 0;
  position: absolute;
  bottom: 100%;
  right: 67px;
  border-width: 0 6px 6px 6px;
  border-style: solid;
  border-color: rgb(231, 207, 245) transparent;
} */

ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 0px;
}

.cursor {
  cursor: pointer;
  margin-top: -5px;
}